<template>
    <el-dialog :visible.sync="sta.show" width="750px">
        <span slot="title">更新整改信息</span>
        <div class="row">
            <div class="col-md-12">
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查类型：</b></label>
                            <span><b>{{ model.TYPE_NAME }}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1" style="float: left;"><b>检查内容：</b></label>
                            <el-table :data="model.ITEM_LIST" style="width: 80%;margin-left: 89px;" border
                                :header-cell-style="headerCellStyle">
                                <el-table-column prop="NAME" label="项目" align="center">
                                </el-table-column>
                                <el-table-column label="情况" align="center">
                                    <template slot-scope="scope">
                                        <el-radio v-model="scope.row.STATUS" :label="0" disabled>合格</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <el-radio v-model="scope.row.STATUS" :label="1" disabled>整改</el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查位置：</b></label>
                            <span><b>{{ model.LOCATION }}</b></span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查日期：</b></label>
                            <span><b>{{ model.DATE }}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>存在问题：</b></label>
                            <span><b>{{ model.PROBLEM }}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>整改人：</b></label>
                            <span><b>{{ model.RECTIFY_NAME }}</b></span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>整改时间：</b></label>
                            <span><b>{{ model.RECTIFY_DATE }}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="sign">
                            <label class="label1"><b>签名：</b></label>
                            <el-image v-if="model.SIGN_KV" :src="model.SIGN_KV.Value" alt="" />
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group" style="display: flex">
                            <label class="label1"><b>整改照片：</b></label>
                            <span class="imgWrap" v-for="(item,index) in model.RECTIFY_IMAGE_KVS" :key="index">
                                <el-image :src="item.Value" :key="'img_'+index" alt="" class="img sh" />
                                <i class="el-icon-delete del" @click="delImg(item.Key)"></i>
                            </span>

                            <el-upload accept="image/*" action="" :limit="5" multiple class="img up"
                                v-if="model.RECTIFY_IMAGE_KVS.length<5" :on-change="onChange" :http-request="uploadFile"
                                :show-file-list="false">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>整改说明：</b></label>
                            <el-input type="textarea" v-model="model.RECTIFY_DESC" style="width: 80%" />
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div slot="footer">
            <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
            <el-button icon="el-icon-check" type="success" @click="submit">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>

    export default {
        name: "detail",
        data() {
            return {
                model: {
                    TYPE_ID: 0,
                    DATE: "",
                    LOCATION: "",
                    PROBLEM: "",
                    CONTENT_JSON: "",
                    RECTIFY_ID: 0,
                    RECTIFY_IMAGE_KVS: []
                },
                user: this.whale.user.get(),
                fileList: [],
                sta: {
                    show: false,
                },

            }
        },
        created() {

        },
        methods: {
            init(model) {
                this.sta = { show: true }
                if (model != undefined && model != null) {
                    this.model = JSON.parse((JSON.stringify(model)));
                } else {
                    this.model = {};
                }
            },

            headerCellStyle({ row, column, rowIndex, columnIndex }) {
                if (row[0].level === 1) {
                    row[1].colSpan = 2;
                    row[2].colSpan = 0;
                    if (columnIndex === 2) {
                        return { display: "none" };
                    }
                }
                return {
                    color: "#333333",
                    fontWeight: "bold",
                    textAlign: "center",
                };
            },
            getImgUrl(val) {
                this.model.RECTIFY_IMAGE = val.join(',')
            },
            submit() {
                let self = this
                this.whale.remote.getResult({
                    url: "/api/School/INS/RecordApi/UpdateRectify",
                    data: this.model,
                    finally() {
                        self.sta.loading = false;
                    },
                    completed: function () {
                        self.sta.show = false
                        self.$emit("on-saved");
                    }
                })
            },
            delImg(key) {
                let self = this
                self.model.RECTIFY_IMAGE_KVS = self.model.RECTIFY_IMAGE_KVS.filter(x => x.Key != key)
            },
            onChange(file) {
                let self = this;
                const isImage = file.raw.type.indexOf("image") >= 0;
                if (!isImage) {
                    self.whale.toast.warn("照片格式不正确")
                    return isImage;
                }
                // const isLt2M = file.raw.size / 1024 / 1024 < 5;
                // if (!isLt2M) {
                //     self.whale.toast.warn("照片大小不能超过5MB")
                //     return false;
                // }
                if (this.model.RECTIFY_IMAGE_KVS.length > 5) {
                    self.whale.toast.warn("最多上传5张照片")
                    return false;
                }
            },
            uploadFile(item) {
                let self = this
                self.whale.image.compress({
                    file: item.file,
                    callback(cf) {
                        self.whale.image.toB64({
                            file: cf,
                            callback(b64) {
                                self.whale.remote.getResult({
                                    url: "/api/School/ToolsApi/UploadImage",
                                    data: { prefix: "ins", base64: b64, },
                                    completed: function (res) {
                                        // console.log(res)
                                        self.model.RECTIFY_IMAGE_KVS.push(res.DATA)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .label1 {
        width: 6em;
        text-align: right;
        margin-right: 5px;
    }

    .img {
        width: 100px;
        height: 100px;
        margin-right: 5px;
        border: 1px solid #cccccc;

    }

    .up {
        text-align: center;
        padding-top: 26px;
        font-size: 20px;
    }

    .imgWrap {
        position: relative;
    }

    .del {
        position: absolute;
        right: 0;
        top: 0;
        color: #e41919;
        font-size: 20px;
        background: #fff;
        cursor: pointer;
    }
</style>